import React, { useState, useEffect, useRef, useMemo } from 'react';
import Slider from "react-slick";
import { Row, Col, Select, Card, Button, Icon, Input, Typography, Tooltip } from "antd";
import OptionModal from "./OptionModal";
import ContentsOfCampaignHolder from "./Styles/contentsOfCampaignStyle"
import { useTranslation } from 'react-i18next';
import RequiredResolutionsCard from "./RequiredResolutionsCardComponent";
import { convertImage } from '../services/contents';
import TOLERANCES from "../constants/Tolerance";
import ModalContentComponent from "./ModalContentComponent";

const { Text } = Typography;

function SliderNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "-15px", top: "108px" }}
            onClick={onClick}
        />
    );
}

function SliderPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "-30px", top: "108px", zIndex: "1" }}
            onClick={onClick}
        />
    );
}

const ContentsOfCampaignComponent = (props) => {
    const { t } = useTranslation();
    const {
        cart,
        listOfContentsInCampaign, // this are contents added to the campaign
        cleanListOfContentsInCampaign,
        deleteContentFromCampaign,
        listResolutionWithContent,
        setListResolutionWithContent,
        updateListOfContentsInCampaign,
        customContents,
        removeRelationFromDisplay,
        clearCustomContents,
        errors,
        groupedDisplays, // When modal open from the group display
        selectedContent,
        toleranceResolution,
        setToleranceResolution,
        isSuperUser,
        editCampaignActive,
        applyRelationOnScreens,
        removeRelationsOnCampaign,
        contentSelected,
        updateCustomContents
    } = props;

    let sliderRef = useRef(null);

    const [visibleModalDeleteContentRelation, setVisibleModalDeleteContentRelation] = useState(false);
    const [contentModal, setContentModal] = useState(null); // Save the content to show in all modal (Delete and info View)
    const [visibleModalCleanContent, setVisibleModalCleanContent] = useState(false);
    const [currentStick, setCurrentStick] = useState(0);
    const [loadingListOfResolutions, setLoadingListOfResolutions] = useState(true); // Waiting for the list of resolutions
    const [slidesToScroll, setSlidesToScroll] = useState(3);
    const [visibleModalContent, setVisibleModalContent] = useState(false);
    // Filter the resolutions that have display is necessary by modal of group displays
    const listResolution = useMemo(() => groupedDisplays ? filteredDisplays() : listResolutionWithContent, [groupedDisplays, listResolutionWithContent]);
    const hasDifferentResolution = useMemo(() => listResolution.some(item => item.contents.some(content => content.differentResolution === true)), [listResolution]);

    const withCardInRelation = 130; // Define the width of the card in the relation
    const { Option } = Select;

    const settingsSlider = {
        infinite: false,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 3,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        variableWidth: true,
        adaptiveHeight: false,
        swipeToSlide: true,
        responsive: [{
            breakpoint: 600,
            settings: { slidesToShow: 2, slidesToScroll: 2 }
        }, {
            breakpoint: 480,
            settings: { slidesToShow: 1, slidesToScroll: 1 }
        }]
    };


    // Function to check if aspect ratios match within a 5% tolerance
    function aspectRatiosMatch(contentAspectRatio, screenAspectRatio) {
        const tolerance = toleranceResolution / 100; // 5%
        const difference = Math.abs(contentAspectRatio - screenAspectRatio);
        return [difference <= tolerance, difference];
    }

    function filteredDisplays() {
        const groupedDisplayIds = groupedDisplays.map(display => display.id);
        const customCart = cart.filter((display) => groupedDisplayIds.includes(display.id))

        return getListOfResolutions(customCart);
    }

    // Iterate over each content resolution
    function matchResolutionContent(contentResolutions, screenResolutions) {
        // Iterate over each content resolution
        let contentMatch = [];
        contentResolutions.forEach(contentResolution => {
            // Calculate content aspect ratio
            const contentAspectRatio = contentResolution.width / contentResolution.height;

            const screenAspectRatio = screenResolutions.resolution_width / screenResolutions.resolution_height;
            // Check if aspect ratios match within tolerance
            const [ismatch, difference] = aspectRatiosMatch(contentAspectRatio, screenAspectRatio);

            if (selectedContent && selectedContent.id !== contentResolution.id) return;
            if (!ismatch) return;

            // Do not load all the content because it overloads the browser.
            const content = {
                ...contentResolution,
                id: contentResolution.id,
                file_thumb: contentResolution.file_thumb,
                name: contentResolution.name,
                content_version_name: contentResolution.content_version_name,
                differentResolution: difference ? ismatch : false,
            }
            contentMatch.push(content);
        });
        return contentMatch;
    }

    // Create the relation depending of customContents
    function createRelation(uniqueResolutionsArray, actionWithRelation) {
        const { displays, contents } = uniqueResolutionsArray;
        let relations = [];
        // This case is when initial campaign
        if (!customContents || customContents.length === 0 || actionWithRelation === "cleanRelations") {
            displays.forEach(display => {
                contents.forEach(content => {
                    relations.push({
                        displayId: display.id,
                        contentId: content.id
                    });
                });
            });
        } else { // When are updating campaign
            displays.forEach(display => {
                if (groupedDisplays) {
                    const groupedDisplayIds = groupedDisplays.map(display => display.id);
                    if (!groupedDisplayIds.includes(display.id)) return;
                }
                let filteredContents = customContents.filter(content => content.displayId === display.id);
                filteredContents.forEach(customContent => {
                    relations.push({
                        displayId: customContent.displayId,
                        contentId: customContent.content.id
                    });
                });

                if(actionWithRelation === "updateContent") {
                    const newContents = listOfContentsInCampaign
                    .filter(content => !customContents
                    .some(customContent => customContent.content.id === content.id));
                    // let newContents = listOfContentsInCampaign.find(content => content.id === newContents.id);
                    if (groupedDisplays && newContents[0]) { //Search all relationships to avoid adding content from previous ones
                        if (contentSelected) {
                            const customContentAux = customContents.map(customContent => {
                                if (customContent.displayId === display.id && customContent.content.id === contentSelected.id) {
                                    return { ...customContent, content: newContents[0]}
                                }
                                return customContent;
                            });
                            updateCustomContents(customContentAux)
                            relations = relations.map(relation => {
                                if (relation.displayId === display.id && relation.contentId === contentSelected.id) {
                                    return { displayId: display.id, contentId: newContents[0].id}
                                }
                                return relation;
                            });
                        }}
                }

                if (actionWithRelation === "addNewContent") {
                    let newContents;
                    if (groupedDisplays) { //Search all relationships to avoid adding content from previous ones
                        newContents = contents
                            .filter(content => !customContents
                            .some(customContent => customContent.content.id === content.id));
                    } else {
                        newContents = contents
                            .filter(content => !filteredContents
                            .some(customContent => customContent.content.id === content.id));
                    }
                    newContents.forEach(content => {
                        relations.push({
                            displayId: display.id,
                            contentId: content.id
                        });
                    });
                }
            });
        }
        return relations;
    };

    // Add relation and contents that match the resolution
    function addRelations(uniqueResolutionsArray, actionWithRelation) {
        return uniqueResolutionsArray.map((relation) => {
            let newRelation = { ...relation, contents: [...relation.contents] };
            newRelation.contents = matchResolutionContent(listOfContentsInCampaign, relation);
            let hasDisplay = true;
            if (groupedDisplays) {
                const groupedDisplayIds = groupedDisplays.map(display => display.id);
                hasDisplay = relation.displays.some(display => groupedDisplayIds.includes(display.id))
            }
            if (newRelation.contents.length > 0 && hasDisplay) {
                newRelation.contentsDisplays = createRelation(newRelation, actionWithRelation);
            }
            return newRelation;
        });
    }

    // Search set of resolutions in the added screens
    function getListOfResolutions(customCart) {
        const uniqueResolutions = {};
        customCart.forEach(item => {
            const resolutionKey = `${item.resolution_height}-${item.resolution_width}`;
            // Do not load all the display because it overloads the browser,
            // only the things that use in this view.
            const displayItem = {
                id: item.id,
                fill_screen: item.fill_screen,
                smart_campaign_cpm: item.smart_campaign_cpm,
            }
            if (!uniqueResolutions[resolutionKey]) {
                uniqueResolutions[resolutionKey] = {
                    resolution_height: item.resolution_height,
                    resolution_width: item.resolution_width,
                    countScreen: 1,
                    contents: [],
                    displays: [displayItem],
                    contentsDisplays: [],
                };
            } else {
                uniqueResolutions[resolutionKey].displays.push(displayItem);
                uniqueResolutions[resolutionKey].countScreen++;
            }
        });
        let uniqueResolutionsArray = Object.values(uniqueResolutions); // Convert object to array
        uniqueResolutionsArray = addRelations(uniqueResolutionsArray);
        return uniqueResolutionsArray;
    }

    const handleContent = (value, content) => {
        let updatedContent = { ...content, name: value };
        updateListOfContentsInCampaign(updatedContent)
        if (editCampaignActive) {
            const updatedlist = listResolutionWithContent.map(relation => {
                const updatedContents = relation.contents.map(item => {
                    if (item.id === content.id) {
                        return { ...item, name: value };
                    }
                    return item;
                });
                return { ...relation, contents: updatedContents };
            });
            setListResolutionWithContent(updatedlist);
        }
    }

    const cleanProgress = () => {
        // Clean the list of contents in the campaign
        cleanListOfContentsInCampaign();
        clearCustomContents();
        showModalCleanContent();
    };

    const showModalDeleteContentRelation = (content) => {
        setContentModal(content)
        setVisibleModalDeleteContentRelation(!visibleModalDeleteContentRelation);
    };

    const showModalCleanContent = () => {
        setVisibleModalCleanContent(!visibleModalCleanContent);
    };

    const deleteContentOfListRelation = () => {
        deleteContentFromCampaign(contentModal.id);
        setVisibleModalDeleteContentRelation(!visibleModalDeleteContentRelation);
    };

    const showModalContent = (content) => {
        if (!content) return;
        setContentModal(content);
        setVisibleModalContent(true);
    };
    const cancelModalContent = () => {
        setContentModal(null);
        setVisibleModalContent(false);
    };

    const calculateSlidesToShow = () => {
        if (toleranceResolution >= 25) return setSlidesToScroll(1);
        if (toleranceResolution >= 15) return setSlidesToScroll(2);
        return setSlidesToScroll(3)
    }

    const initSlider = () => {
        if (!sliderRef) return
        sliderRef.slickGoTo(0);
    }

    const updateListResolutions = (type) => {
        if (loadingListOfResolutions || listResolutionWithContent.length === 0) return;

        const updatedListResolution = addRelations(listResolutionWithContent, type);
        setListResolutionWithContent(updatedListResolution);
        if (groupedDisplays && !contentSelected) applyRelationOnScreens(groupedDisplays, updatedListResolution);
    };

    // *** useEffect *** //
    useEffect(() => {
        if (!groupedDisplays) setListResolutionWithContent(getListOfResolutions(cart));
        setLoadingListOfResolutions(false);
    }, []);

    useEffect(() => {
        initSlider();
        calculateSlidesToShow();
        updateListResolutions("cleanRelations");
    }, [toleranceResolution])

    useEffect(() => {
        if( groupedDisplays && contentSelected) {
            updateListResolutions("updateContent");
        } else {
            updateListResolutions("addNewContent");
        }
    }, [listOfContentsInCampaign]);

    const filteredContents = (relation) => {
        return (contentSelected
            ? relation.contents.filter((content) => contentSelected.id === content.id)
            : relation.contents);
    }

    return (
        <ContentsOfCampaignHolder withCardInRelation={withCardInRelation} >
            <Row>
                {!groupedDisplays &&
                    <Col xs={24} sm={24} md={24} lg={24} style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Tooltip title={t('Clear all contents added to the campaign, clearing its progress')}>
                        <Button className="secondary buttonContent standard" onClick={() => showModalCleanContent()}>
                            <Icon type="delete" />
                            {t('Clear progress')}
                        </Button>
                    </Tooltip>
                    </Col>
                }
                <Col xs={24} sm={24} md={24} lg={24} >
                    <div className="slider-container">
                        {listOfContentsInCampaign.length === 0 ?
                            <div id="1" className='contentMessage'>
                                <p>{t("You must select a content")}</p>
                            </div>
                            :
                            <Slider {...settingsSlider}>
                                {listOfContentsInCampaign.length > 0 && listOfContentsInCampaign.map((content, index) => (
                                    <div key={index} style={{ padding: "0 8px" }}>
                                        <Card className='cardContent'>
                                            <div style={{ display: "flex" }}>
                                                <Input
                                                    size="small"
                                                    className='inputContentName'
                                                    placeholder={t('Identify your ad...')}
                                                    value={content.name}
                                                    onChange={e => handleContent(e.target.value, content)}
                                                />
                                                <span className={"primaryCircle"} >
                                                    {content.content_version_name || "V1"}
                                                </span>
                                            </div>
                                            <div className='centerCard'>
                                                {<img
                                                    src={convertImage(content.file_thumb)}
                                                    onClick={() => showModalContent(content)}
                                                    className="contentCardImage"
                                                    alt=""
                                                />}
                                            </div>
                                            <div className='contentCardContent'>
                                                <p className="textCard centerCard" > {content.width} x {content.height}</p>
                                                <Tooltip title={t('Remove contents from the campaign')}>
                                                    <Button
                                                        shape="circle"
                                                        className="icon-card cardRed"
                                                        onClick={() => showModalDeleteContentRelation(content)}
                                                    >
                                                    <Icon type="delete" />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </Card>
                                    </div>
                                ))}
                            </Slider>
                        }
                    </div>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20}>
                    <div className="ant-collapse-header" style={{ marginTop: "10px", paddingBottom: "10px !important" }} role="button" tabIndex="0" aria-expanded="true">
                        {t('Required resolutions')}
                        {errors && errors["contentsDisplays"] &&
                            <p className="error-message error-message-contents-displays">
                                {t(errors["contentsDisplays"])}
                            </p>
                        }
                    </div>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                    <div class="contentTolerance" style={{ marginTop: '30px' }} role="button" tabIndex="0" aria-expanded="true">
                        <p style={{ marginRight: "5px" }}>{t("Tolerance")}:</p>
                        <Select
                            showSearch
                            style={{ width: 70 }}
                            defaultValue={toleranceResolution}
                            onChange={(value) => setToleranceResolution(value)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {TOLERANCES.map(tolerance => (
                                (tolerance.isSuper && !isSuperUser) ? null : (
                                    <Option
                                        value={tolerance.value}
                                    >
                                        {tolerance.label}
                                    </Option>
                                )
                            ))}
                        </Select>
                        <Tooltip
                            placement="right"
                            title={t('Info tolerance')}
                        >
                            <Icon className="cardAudienceIconInfo" type="info-circle" theme="outlined" />
                        </Tooltip>
                    </div>
                </Col>
                {hasDifferentResolution && (
                    <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: "20px" }} className="appear-animation">
                        <Row type="flex" style={{ gap: "10px", alignItems: "center" }}>
                            <Icon type="warning" theme="outlined" style={{ color: "#FAAD14" }} />
                            <Text style={{ color: "#888888", fontSize: "17px", fontStyle: "italic" }}>
                                {t("There are ads that do not adapt correctly to the resolutions. To continue, adjust the tolerance.")}
                            </Text>
                        </Row>
                    </Col>
                )}
                <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="slider-container required-resolution">
                        <Slider
                            ref={slider => { sliderRef = slider }}
                            {...settingsSlider}
                            slidesToScroll={slidesToScroll}
                            afterChange={(current) => setCurrentStick(current)}
                        >
                            {listResolution && listResolution.map((relation, index) => (
                                <div key={index} style={{ padding: "0 8px", position: "relative" }}>
                                    {relation.contents.length > 0 ? (
                                        <div className="contentRelation" style={{ width: `${withCardInRelation + (relation.contents.length * 40)}px` }}>
                                            <Card>
                                                { filteredContents(relation).map((content, i) => (
                                                    <RequiredResolutionsCard
                                                        key={i}
                                                        customContents={customContents}
                                                        removeRelationFromDisplay={removeRelationFromDisplay}
                                                        setListResolutionWithContent={setListResolutionWithContent}
                                                        listResolutionWithContent={listResolutionWithContent}
                                                        loading={index < (currentStick - 8) || index > (currentStick + 16)}
                                                        className="multi"
                                                        style={{ left: `${i * 40}px`, zIndex: (relation.contents.length - i) }}
                                                        relation={relation}
                                                        content={content}
                                                        showModalDelete={showModalDeleteContentRelation}
                                                        isWarning={content.differentResolution}
                                                        removeRelationsOnCampaign={removeRelationsOnCampaign}
                                                        onClickImage={() => showModalContent(content)}
                                                    />
                                                ))}
                                            </Card>
                                        </div>
                                    ) : (
                                        <RequiredResolutionsCard
                                            loading={index < (currentStick - 8) || index > (currentStick + 16)}
                                            relation={relation}
                                            showModalDelete={showModalDeleteContentRelation}
                                            removeRelationsOnCampaign={removeRelationsOnCampaign}
                                            onClickImage={() => showModalContent(relation)}
                                        />
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </Col>
            </Row>
            <ModalContentComponent
                visible={visibleModalContent}
                onCancel={cancelModalContent}
                content={contentModal}
            />
            <OptionModal
                title={t('Atention!')}
                body={t('Are you sure you want to remove this content from the campaign')}
                visible={visibleModalDeleteContentRelation}
                okText={t('OK')}
                cancelText={t('Cancel')}
                onOk={() => deleteContentOfListRelation()}
                onCancel={() => showModalDeleteContentRelation()}
                okButtonProps={{ loading: true }}
                width={600}
            />
            <OptionModal
                title={t('Atention!')}
                body={t('Are you sure you want to delete all progress so far?')}
                visible={visibleModalCleanContent}
                okText={t('OK')}
                cancelText={t('Cancel')}
                onOk={() => cleanProgress()}
                onCancel={() => showModalCleanContent()}
                okButtonProps={{ loading: true }}
            />
        </ContentsOfCampaignHolder>
    )
}

export default ContentsOfCampaignComponent;
import React from 'react';
import { useTranslation } from "react-i18next";
import { Icon, Row, Tooltip, Typography } from "antd";
import CartBudgetStyleHolder from "./Styles/cartBudgetStyle";
import NumberFormat from "./NumberFormat";

const { Text, Title } = Typography;

const CartBudgetComponent = ({ stepsCurrent, updateByAmountBudget, realAmount, currentCampaign, editCampaignActive, currency }) => {
  const { t } = useTranslation();

  const calculatedBudget = Math.round(realAmount * 100) / 100;
  const currentCurrency = editCampaignActive ? currentCampaign.currency : currency;
  const isPaymentStatusApproved = editCampaignActive && currentCampaign.payment_status === 'approved';

  return (
    <CartBudgetStyleHolder>
      <div className="cartBudget">
        <Row
          type="flex"
          justify="space-between"
        >
          <Title
            level={3}
            className="text-title"
          >
            {t('Updated budget')}
          </Title>
          {((stepsCurrent !== 0) && !isPaymentStatusApproved ) && (
            <Tooltip
              className="appear-animation-without-movement"
              placement="right"
              title={t('Go back to the original budget and recalculate impressions (changes you have made in impressions for each Spot will be erased)')}
            >
              <Icon
                onClick={updateByAmountBudget}
                type="undo"
              />
            </Tooltip>
          )}
        </Row>
        <Row>
          <Text>
            <NumberFormat
              value={calculatedBudget}
              currency={currentCurrency}
            />
          </Text>
        </Row>
      </div>
    </CartBudgetStyleHolder>
  );
}

export default CartBudgetComponent;
